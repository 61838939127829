import cn from 'classnames';
import dayjs from 'dayjs';
import TZ from 'dayjs/plugin/timezone';
import UTC from 'dayjs/plugin/utc';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ChangeEventHandler, FC, useEffect, useRef, useState } from 'react';
import useSWR from 'swr';

import DateSelector from '~components/Billboard/BillboardFilters/DateSelector';
import Schedules from '~components/Billboard/Schedules';
import PresaleCountDown from '~components/Movies/PresaleCountDown';
import EmptyState from '~components/ui/EmptyState';
import Loading from '~components/ui/Loading';
import Poster from '~components/ui/Poster';
import Select from '~components/ui/Select/Select';
import parseQuery from '~utils/api/parseQuery';
import go from '~utils/go';
import useGlobalState from '~utils/hooks/useGlobalState';
import useOnScreen from '~utils/hooks/useOnScreen';
import { NormalizedComingSoon } from '~utils/normalizers/normalizeComingSoon';

import { Cinema } from '~types';

import s from '../PresaleMovie/PresaleMovie.module.css';

dayjs.extend(UTC);
dayjs.extend(TZ);

interface Props {
  data: NormalizedComingSoon;
  cinemaId?: string;
}

const OnPresaleMovie: FC<Props> = ({ data, cinemaId }) => {
  const { query, pathname } = useRouter();
  const CinemaId = query?.c || query?.CinemaId || cinemaId;
  const ref = useRef<Element>(null);
  const isVisible = useOnScreen(ref);
  const [shouldLoadSchedule, setShouldLoadSchedule] = useState(false);

  const [date, setDate] = useState('');
  const cinemaMovie = data?.CinemaMovies?.find((movieData) => movieData.enablePresale);
  const [cinemas] = useGlobalState<Cinema[]>('/cinemas/list');
  const cinema = cinemas?.find?.((item) => `${item.id}` === CinemaId);
  const tz = cinema?.timezone || 'America/Hermosillo';

  const isAfterPremiere = dayjs().isAfter(dayjs(cinemaMovie?.premiereAt));
  const isAfterPreview = cinemaMovie?.previewAt
    ? dayjs().isAfter(dayjs(cinemaMovie?.previewAt))
    : true;
  const isAfterPresaleStart = dayjs().isAfter(dayjs(cinemaMovie?.presaleStartAt));

  const { data: dates, isLoading: loadingDates } = useSWR<Array<{ date: string }>>(
    shouldLoadSchedule
      ? `/shows/dates?MovieId=${data.id}${CinemaId ? `&CinemaId=${CinemaId}` : ''}`
      : null,
  );

  const { data: shows, isLoading: loadingShows } = useSWR(
    shouldLoadSchedule && date
      ? `/billboard?MovieId=${data.id}${CinemaId ? `&CinemaId=${CinemaId}` : ''}&date=${date}`
      : null,
    {
      keepPreviousData: true,
      revalidateOnFocus: false,
    },
  );

  const loading = loadingDates || loadingShows;

  useEffect(() => {
    if (isVisible) setShouldLoadSchedule(true);
  }, [isVisible]);

  useEffect(() => {
    if (!!dates?.length) {
      setDate(dates?.[0].date);
    }
  }, [dates]);

  const handleCinemaChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    go(`${pathname}?${parseQuery({ ...query, c: e.target.value })}`, {
      shallow: true,
      scroll: false,
    });
  };

  return (
    <div className={cn(s.root, s.onPresale)} ref={ref as any}>
      <Link href={`/pelicula/${data.slug}`} className="relative block">
        {data?.poster && (
          <Poster
            data={data?.poster}
            version="url"
            sizes="(min-width: 1280px) 320,(min-width: 1024px) 288px, (min-width: 640px) 213px, 320px"
          />
        )}
        <div className={s.presaleTagContainer}>
          <span className={s.presaleTag}>En Preventa</span>
        </div>
      </Link>
      <div className={s.info}>
        <div className="flex flex-col">
          <h3 className={s.title}>
            <Link href={`/pelicula/${data.slug}`}>{data?.title}</Link>
          </h3>
          {data?.originalTitle && <span className="quote">{data?.originalTitle}</span>}
          {CinemaId && (
            <span className="text-secondary">
              {`${cinemaMovie?.previewAt ? 'Preestreno' : 'Estreno'} :`}{' '}
              {dayjs(cinemaMovie?.previewAt ?? cinemaMovie?.premiereAt)
                .tz(tz)
                .format('DD [de] MMMM [del] YYYY')}
            </span>
          )}
        </div>
        {!CinemaId ? (
          <>
            <div className="flex w-full flex-col items-center sm:max-w-[274px]">
              {!!cinemaMovie?.previewAt && !isAfterPreview && isAfterPresaleStart && (
                <PresaleCountDown date={cinemaMovie?.previewAt} type="preview" />
              )}
              {isAfterPreview &&
                cinemaMovie?.premiereAt &&
                !isAfterPremiere &&
                isAfterPresaleStart && (
                  <PresaleCountDown date={cinemaMovie?.premiereAt} type="premiere" />
                )}
              <span className={s.premierDate}>
                {cinemaMovie?.previewAt ? (
                  <>
                    Preestreno:{' '}
                    {dayjs(cinemaMovie?.previewAt)
                      .tz(tz)
                      .format('DD [de] MMMM [del] YYYY')}
                  </>
                ) : (
                  <>
                    Estreno:{' '}
                    {dayjs(cinemaMovie?.premiereAt)
                      .tz(tz)
                      .format('DD [de] MMMM [del] YYYY')}
                  </>
                )}
              </span>
            </div>
            <div className="space-y-4 my-8">
              <span className="block text-xl text-white/60">
                Selecciona un cine para mostrar los horarios de esta película
              </span>
              <Select
                placeholder="Selecciona tu cine favorito"
                className="!w-auto"
                type="primary"
                onChange={handleCinemaChange}
              >
                <option value="">Selecciona tu cine favorito</option>
                {cinemas?.map((cinema) => (
                  <option value={cinema.id} key={cinema.id}>
                    {cinema.name}
                  </option>
                ))}
              </Select>
            </div>
          </>
        ) : (
          <Loading theme="dark" size="large" show={loading} label="Cargando horarios">
            <DateSelector
              disableInitialScroll
              className={s.dateSelector}
              buttonClassname={s.dateSelectorButton}
              ignoreToday
              date={date}
              onChange={setDate}
              dates={dates?.map((item) => item.date) || []}
              timezone={tz}
            />
            <div className="min-h-[120px] mt-8 flex-1">
              {!!shows?.length ? (
                <Schedules
                  shows={shows}
                  movieSlug={data?.slug}
                  title={`Horarios de ${cinema?.name || ''}`}
                  disabled={!cinema?.onlineSale}
                  timezone={tz}
                />
              ) : (
                <div className="h-full flex justify-center">
                  <EmptyState
                    type="movie"
                    title="No hay horarios disponibles para esta pélicula en este momento"
                    className="py-32"
                    theme="dark"
                  />
                </div>
              )}
            </div>
          </Loading>
        )}
      </div>
    </div>
  );
};
export default OnPresaleMovie;
